import { useQuery } from '@tanstack/react-query';
import { eventKeys } from './eventKeys';
import { getSearchEvents } from './api/EventsApi';

const useGetSearchEvents = (params, queryConfig = {}) => {
  return useQuery(eventKeys.list(params), () => getSearchEvents(params), {
    ...queryConfig,
  });
};

export default useGetSearchEvents;
