import React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import queryString from "query-string";
import { size as _size } from "lodash";

import Layout from "../layouts/Layout";
import Seo from "../components/Seo/Seo";
import EventCard from "../components/EventCard/EventCard";

import useGetSearchEvents from "../services/events/useGetSearchEvents";

const SearchResultPage = location => {
  const intl = useIntl();

  const urlParams = queryString.parse(location.location.search);
  const urlParamsStringified = queryString.stringify(urlParams);


  const { data: events, isLoading } = useGetSearchEvents(urlParamsStringified, {
    enabled: !!urlParamsStringified
  });


  return (
    <Layout section={"result"}>
      <section className="section homepage-content">
        <div className="container">
          {isLoading ? (
            <div className="loader-wrapper is-active">
              <div className="loader is-loading"></div>
            </div>
          ) : (
            <>
              {_size(events) > 0 ? (
                <>
                  <div className="columns">
                    <div className="column">
                      <div className="content">
                        <div className="level row-heading">
                          <div className="level-left">
                            <h3>{intl.formatMessage({ id: "search_results" })}</h3>
                          </div>
                        </div>
                        <div className="columns is-multiline row-items">
                          {events.map(event => (
                            <EventCard key={event.id} event={event} />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="columns">
                  <div className="column">
                    <div className="content has-text-centered">
                      <p>No Events found with this search filter</p>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

        </div>
      </section>
    </Layout>
  );
};

export default SearchResultPage;

export const Head = ({ location, pageContext }) => (
  <Seo
    title={pageContext.intl.messages.search_results}
    pathname={location.pathname}
  />
);

